// Generated by .maintenance/generators/routes.js
import AppLocaleDefault from "src/paf.es/locales/es.json";

export default [
  {
    name: "maintenance-es",
    path: "/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "paf.es",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "restricted-es",
    path: "/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "paf.es - restricted",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "maintenance-en",
    path: "/en/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "paf.es",
      localeLoader: () => import("../paf.es/locales/en.json")
    }
  },
  {
    name: "restricted-en",
    path: "/en/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "paf.es - restricted",
      localeLoader: () => import("../paf.es/locales/en.json")
    }
  },
  {
    path: "*",
    component: () => import("src/.maintenance/views/404.vue"),
    meta: {
      title: "paf.es - 404"
    }
  }
];
